export const CRISP_FAQ = "https://aide.cfas.apprentissage.beta.gouv.fr/fr/";
export const SUPPORT_PAGE_ACCUEIL = "/contact";

const GESTION_COMPTE_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const TRANSMISSION_DONNEES_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const EFFECTIFS_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const SIFA_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
const AUTRE_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const GC_SUPPRESSION_COMPTE_ELEMENT_LINK = ` ${GESTION_COMPTE_GROUP}`;

export const TD_API_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;
export const TD_MANUEL_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;

export const EFF_EDITION_ELEMENT_LINK = `${EFFECTIFS_GROUP}`;

export const AUTRE_AMELIORATION_ELEMENT_LINK = `${AUTRE_GROUP}`;
export const AUTRE_AFFELNET_LINK = `${AUTRE_GROUP}`;
